<template>
    <div class="wrapper clickable">
        <div class="collbtn" :class="visible ? null : 'collapsed'" :aria-expanded="visible ? 'true' : 'false'"
            :aria-controls="`collapse-${id}`" @click="visible = !visible">
            <slot name="title"></slot>
            <b-icon icon="chevron-down"></b-icon>
        </div>

        <b-collapse :id="`collapse-${id}`" v-model="visible" class="mt-2">
            <b-card class="faq-card">
                <p class="card-text">{{ text }}</p>
                <slot class="card-text" name="text"></slot>
            </b-card>
        </b-collapse>
    </div>
</template>

<script>
export default {
    name: "Collapse",
    props: {
        id: Number,
        label: String,
        text: String
    },
    data() {
        return {
            visible: false
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    margin-bottom: 1rem;
}

.faq-card {
    border: none;
    padding: 0px 16px 0px 16px ;
}

.collbtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    gap: 16px;

    width: 100%;

    background: #FFFFFF;

    /* Card */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .b-icon.bi,
    .btn .b-icon.bi {
        font-size: inherit;
        transition: 0.1s;
    }

    &[aria-expanded="true"] .b-icon {
        transform: rotate(180deg);
    }

}
</style>
