<template>
    <div>
        <section id="navigation">
            <div id="main-nav">
                <div id="logo">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-activity" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
                        </svg>
                    </div>

                    <div class="text">
                        ELSI-SAT H&C
                    </div>
                </div>

                <div id="optional">
                    <div onclick="document.getElementById('tool-overview').scrollIntoView()">Toolbereiche</div>
                    <div onclick="document.getElementById('cards').scrollIntoView()">Mögliche Nutzung</div>
                    <div onclick="document.getElementById('faq').scrollIntoView()">FAQ</div>
                    <div onclick="document.getElementById('ueber-uns').scrollIntoView()">Über uns</div>
                </div>
            </div>
        </section>

        <section id="header">
            <div class="big-logo">
                <img class="img-fluid" src="~@/assets/images/elsisat/bmbf_logo_neu_skaliert.svg" alt="">
            </div>
            <div id="hero">
                <div id="text">
                    <hgroup>
                        <h1 class="hero">ELSI-SAT H&C</h1>

                        <p class="subhead">Mehr Ethik und Recht in Ihrem Forschungsprojekt im
                            Health&nbsp;&amp;&nbsp;Care (H&C) Bereich
                        </p>
                    </hgroup>
                    <p> In der Forschung wird die Berücksichtigung <strong>ethischer, rechtlicher und sozialer
                            Aspekte</strong> (ELSA oder engl. ELSI) zunehmend wichtiger. Doch das Themengebiet ist
                        umfangreich und wenig übersichtlich, sodass eine systematische Beschäftigung damit schwerfallen
                        kann..
                    </p>
                    <p>Das ELSI-Screening- und Awarenesstool (SAT) ist ein <strong>Werkzeug zum Lernen sowie zur Inspiration
                            und Reflexion</strong>.
                        Es soll Forschende der unterschiedlichsten Fachrichtungen dabei unterstützen, ELS-Aspekte
                        bereits ab der gemeinsamen Antragsidee in das Forschungsprojekt einzubringen. ELSI-SAT H&C ist für
                        Forschungsideen in den Bereichen Gesundheit und Pflege konzipiert. Es kann sowohl von einer
                        einzelnen Person als auch von einem Team anonym genutzt werden.</p>
                    <div style="display: flex; gap: 40px">
                        <Button label="Weitere Informationen" btnStyle="btn-secondary" icon="ArrowDownIcon"
                            onclick="document.getElementById('tool-overview').scrollIntoView()"></Button>
                        <Button v-b-modal.modal-multi-1 label="ELSI-SAT H&C starten" :chevron="true" />
                    </div>
                    <div>
                        <b-modal id="modal-multi-1"
                            title="Wählen Sie eine der folgenden Optionen, um Ihr Projekt in ELSI-SAT H&C zu beginnen"
                            ok-title="Zur Projektübersicht" cancel-title="Abbrechen" @ok="createOK" size="xl"
                            footer-class="modal-footer-buttons">
                            <ProjectCreationModal ref="projectCreationModal" />
                        </b-modal>
                    </div>

                    <!-- <router-link :to="{name: 'uebersicht'}">Uebersicht </router-link> -->

                </div>
                <div id="image">
                    <img class="img-fluid" src="~@/assets/images/home/home.svg" width="600px" alt="">
                </div>
            </div>
        </section>
        <section id="tool-overview">
            <h5>Toolbereiche</h5>
            <div class="tool-overview">
                <div class="tool-column">
                    <div id="image">
                        <img class="img-fluid" src="~@/assets/images/overview/valueReflection.svg" width="75%" alt="">
                    </div>
                    <div class="tool-step">
                        <h6><span class="tool-step-circle">01</span></h6>
                        <div class="tool-text">Betrachten Sie zentrale Werte für Ihr Projekt aus unterschiedlichen
                            Perspektiven.</div>
                    </div>
                </div>
                <div class="tool-column">
                    <div id="image">
                        <img class="img-fluid" src="~@/assets/images/overview/profile.svg" width="75%" alt="">
                    </div>
                    <div class="tool-step">
                        <h6><span class="tool-step-circle">02</span></h6>
                        <div class="tool-text">Orientieren Sie sich mithilfe eines Fragebogens, welche ELSI-Kategorien für
                            Ihr Projekt besonders relevant sind.</div>
                    </div>
                </div>
                <div class="tool-column">
                    <div id="image">
                        <img class="img-fluid" src="~@/assets/images/overview/cards.svg" width="75%" alt="">
                    </div>
                    <div class="tool-step">
                        <h6><span class="tool-step-circle">03</span></h6>
                        <div class="tool-text">Informieren Sie sich umfassend zu verschiedenen ELSI-Kategorien.</div>
                    </div>
                </div>
                <div class="tool-column">
                    <div id="image">
                        <img class="img-fluid" src="~@/assets/images/overview/reflection.svg" width="75%" alt="">
                    </div>
                    <div class="tool-step">
                        <h6><span class="tool-step-circle">04</span></h6>
                        <div class="tool-text">Lassen Sie sich mit Reflexionsfragen für die nächsten Schritte inspirieren.
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="cards">
            <h5>Mögliche Nutzung</h5>

            <div class="cards">
                <CardApplications h6="Alleine reinschnuppern" dura="(~30 Minuten)" icon="UserIcon"
                    text="Sehen Sie sich die Funktionen des Tools in Ruhe an und gewinnen Sie einen Überblick über alle ELSI-Kategorien." />

                <CardApplications h6="Bearbeitung im kleinen Team" dura="(~2-3 Stunden)" icon="UsersIcon"
                    text="Bearbeiten Sie zusammen die verschiedenen Bereiche des Tools und vertiefen Sie Ihr Verständnis." />

                <CardApplications h6="Diskussion im Konsortium" dura="(~1-2 Tage)" icon="MessageCircleIcon"
                    text="Nehmen Sie sich gemeinsam Zeit, auf alle Aspekte einzugehen und reflektieren Sie Ihr eigenes Forschungsprojekt umfassend." />
            </div>
        </section>

        <section id="faq">
            <h5>Die wichtigsten Fragen und Antworten (FAQ)</h5>

            <div class="faq">
                <Collapse :id=1>
                    <template #title>
                        Was ist ELSI-SAT Health & Care?
                    </template>
                    <template #text>
                        Das ELSI-Tool ist in erster Linie ein <strong>Orientierungstool</strong>. Es soll Forschenden auf
                        einfache und spielerische Art und Weise einen ersten Zugang zu den komplexen ethischen, sozialen und
                        rechtlichen Themen im Bereich Health & Care bieten.
                    </template>
                </Collapse>

                <Collapse :id=2>
                    <template #title>
                        Was ist das Ziel des Tools?
                    </template>
                    <template #text>
                        Den Forschenden und Projektteams soll eine <strong>selbstständige Einschätzung</strong> hinsichtlich
                        der wichtigsten ELS-Aspekte möglich sein. Diese Einschätzung bezieht sich auf ihre (geplanten)
                        Projekte in den Bereichen Gesundheit und Pflege. Wir wollen die Forschenden dabei unterstützen, eine
                        Entscheidung darüber zu treffen, wieviel Bedeutung den ethischen, rechtlichen und sozialen
                        Fragestellungen in ihrem Projekt beigemessen werden sollte. Daraus kann sich ein Bedarf an
                        zusätzlicher, womöglich externer Expertise in ihrem Konsortium ergeben.
                    </template>
                </Collapse>
                <Collapse :id=3>
                    <template #title>
                        Was kann das Tool nicht?
                    </template>
                    <template #text>
                        Wir bieten <strong>keine</strong> individuelle, auf das jeweilige Projekt im Detail angepasste,
                        <strong>ethische Beratung</strong>. Zudem stellt dieses Tool auch <strong>keine
                            Rechtsberatung</strong> dar, es kann sie auch nicht ersetzen. Wir können keine vollständige
                        Analyse einzelner, noch unbekannter Projekte liefern. Daher ersetzt das Tool keine
                        projektbegleitende ethische, rechtliche oder sozialwissenschaftliche Expertise und Kompetenz, die
                        intern oder extern eingeholt wird.
                    </template>
                </Collapse>

                <Collapse :id=4>
                    <template #title>
                        Was bringt mir das Tool?
                    </template>
                    <template #text>
                        <p>
                            Das Tool wurde primär für die Anfangsphase von Forschungsprojekten konzipiert, kann aber auch im
                            weiteren Projektverlauf genutzt werden. Wir möchten Forschende informieren und ihnen ein
                            <strong>Basiswissen</strong> - insbesondere anhand der sog. ELSI-Karten - vermitteln.
                            Gleichzeitig ist der Aufbau und die Abfolge der jeweiligen Schritte des Tools darauf ausgelegt,
                            eine <strong>Reflexion und Diskussion</strong> anzuleiten, ohne dass spezielles Vorwissen
                            gebraucht wird. So wird eine selbstständige Einschätzung
                            ermöglicht.
                        </p>
                        <p>
                            Dadurch können Forschende ihre Projekte und Projektanträge bereichern und verbessern. Das Tool
                            hilft dabei, die relevanten ELS-Aspekte zu erkennen und einzuschätzen. Jede Forschung, die mit
                            öffentlichen (Dritt-)Mitteln finanziert wird, sollte letztlich auch einen <strong>gewissen
                                Beitrag für die Gesellschaft</strong> leisten. Es ist daher dessen gesellschaftliche
                            Relevanz zu hinterfragen. Je mehr die Forschenden den Blick "über den Tellerrand", d.h. über
                            rein technische Aspekte hinauswagen und sich gesellschaftlichen Fragen stellen, desto
                            interessanter und wertvoller kann auch der soziale Beitrag und Nutzen ihrer Forschung sein. Das
                            Tool hilft also Entwickelnden und Forschenden dabei, bereits ab dem Antrag den Fokus auf die
                            Anwendung und den Nutzen ihrer Forschung zu legen.
                        </p>
                    </template>
                </Collapse>
                <Collapse :id=5>
                    <template #title>
                        Wer sollte das Tool benutzen?
                    </template>
                    <template #text>
                        <p>ELSI-SAT H&C richtet sich primär an <strong>Forschende im Bereich der
                                Mensch-Technik-Interaktion</strong>.
                            Insbesondere der Fragebogen und das ELSI-Profil sind aus diesem Grund schwerpunktmäßig auf die
                            Technikentwicklung in Forschungsprojekten ausgerichtet.
                            Die <i>Wertereflexion</i>, die <i>ELSI-Karten</i> und die <i>Projektreflexion</i> haben wir
                            jedoch möglichst offen
                            gestaltet, so dass auch Forschende aller Fachrichtungen, Studierende oder interessierte Laien
                            ELSI-SAT H&C als ein Werkzeug zum Lernen sowie zur Inspiration und Reflexion nutzen können.</p>
                        <p>
                            <i>Soll das Tool von Einzelpersonen oder vom gesamten Konsortium beziehungsweise Team genutzt
                                werden?
                            </i>
                        </p>
                        <p>
                            Wir empfehlen beides. In einem ersten Schritt können <strong>Einzelpersonen</strong> das
                            ELSI-Tool benutzen, um einen groben Eindruck zu erhalten und eine Vorstellung über die Bedeutung
                            von ELSI-Aspekten in ihrem Projekt zu bekommen. Sie können erste Ideen sammeln und ihre eigene
                            Skizze anreichern. In einem zweiten Schritt empfehlen wir <strong>Teams</strong>, die einzelnen
                            Ideen ihrer Mitglieder zusammenzuführen.
                            Die <i>Projektreflexion</i> kann dabei helfen, gemeinsame Ideen zu entwickeln und die Vorteile
                            der Diskussion und des Austauschs zu nutzen. Ebenso sollte ein Projekt ein gemeinsames
                            Werteverständnis haben. Hierfür dient die <i>ELSI-Wertereflexion</i>. Diese Aufgaben im
                            ELSI-Tool sind vor allem für das Team als Ganzes sinnhaltig und wertvoll.
                        </p>
                    </template>
                </Collapse>

                <Collapse :id=6>
                    <template #title>
                        Welchen wissenschaftlichen Hintergrund hat das Tool?
                    </template>
                    <template #text>
                        Das ELSI-Tool stützt sich bei der Wissensvermittlung auf eine intensive
                        <strong>Literaturrecherche</strong>, insbesondere in den Bereichen Medizinethik und Technikrecht.
                        Der Aufbau und die Aufgabenstellungen im Tool wurden durch verschiedene, größtenteils bereits
                        etablierte Methoden inspiriert. Das ELSI-Profil knüpft an Methoden der Technikfolgenabschätzung an,
                        während die <i>Wertereflexion</i> und <i>Projektreflexion</i> Ideen der "Value Sensitive
                        Design-Methode" aufgreifen. Das Tool wurde durch <strong>qualitative</strong> Befragungen von
                        Wissenschaftler*innen aus verschiedenen Forschungsbereichen mehrfach evaluiert und optimiert.
                    </template>
                </Collapse>
            </div>
        </section>

        <section id="ueber-uns">
            <h5>Über uns</h5>

            <div class="wrapper">
                <div class="text">
                    <h5>Das Forschungsteam</h5>
                    <p>Die Teilnehmenden des ELSI-SAT H&C-Projekts waren ein interdisziplinäres Team aus den Fachgebieten
                        Ethik, Informatik, Recht, Usability und User Experience. Das Software-Tool wurde gemeinsam
                        inhaltlich konzipiert, iterativ getestet und technisch umgesetzt.</p>

                    <h5>Das Konsortium</h5>
                    <p>Das Forschungsprojekt ELSI-SAT H&C wurde von Oktober 2021 bis Juni 2023 durch das Bundesministerium
                        für Bildung und Forschung (BMBF) gefördert. Somit befindet es sich derzeit auf dem Stand Juni 2023
                        und wird förderungsbedingt auch nicht regelmäßig aktualisiert.</p>
                    <p>Das Tool wurde durch folgende Projektpartner entwickelt und realisiert:</p>
                    <p>
                        <strong>Institut für Digitale Ethik</strong> der Hochschule der Medien Stuttgart<br>
                        <strong>Information Experience and Design Research Group</strong> der Hochschule der Medien
                        Stuttgart<br>
                        <strong>Center for Life Ethics</strong> der Rheinischen Friedrich-Wilhelms-Universität Bonn
                    </p>
                </div>

                <div class="logos">
                    <div class="logo">
                        <img class="img-fluid" src="~@/assets/images/elsisat/bmbf_logo.svg" alt="">
                    </div>
                    <div class="logo">
                        <img class="img-fluid" src="~@/assets/images/elsisat/centerLifeEthics.svg" alt="">
                    </div>
                    <div class="logo">
                        <img class="img-fluid" src="~@/assets/images/elsisat/hdm_logo.svg" alt="">
                    </div>
                    <div class="logo">
                        <img class="img-fluid" src="~@/assets/images/elsisat/ide_logo.svg" alt="">
                    </div>
                    <div class="logo">
                        <img class="img-fluid" src="~@/assets/images/elsisat/ixd.svg" alt="">
                    </div>
                    <div class="logo">
                        <img class="img-fluid" src="~@/assets/images/elsisat/bonn_logo_desktop.svg" alt="">
                    </div>
                </div>
            </div>
        </section>

        <div class="footer">
            ELSI-SAT Health & Care
            <router-link :to="{ name: 'impressum' }"> <span class="mx-3">Impressum</span></router-link>
            <router-link :to="{ name: 'datenschutz' }"> <span class="mx-3">Datenschutz</span></router-link>
        </div>

        <div class="footer version-info">
            Applikation: {{ appVersion }} | Daten: {{ dataVersion }}
        </div>

    </div>
</template>

<script>
import Button from "../components/Button.vue";
import CardApplications from "../components/CardApplications.vue";
import Collapse from "../components/Collapse.vue";
import Modal from "../components/Modal.vue";
import HomepageModal from "../layouts/components/snippets/HomepageModal.vue";
import ProjectCreationModal from "../components/Modal_Project_Creation.vue"
import ImportJSON from "../components/ImportJSON.vue"

export default {
    name: 'Home',
    components: {
        Button,
        CardApplications,
        Collapse,
        Modal,
        HomepageModal,
        ProjectCreationModal,
        ImportJSON
    },
    data() {
        return {
            images: {
                hdmlogo: require("@/assets/images/elsisat/hdm_logo.svg"),
                bmbflogo: require("@/assets/images/elsisat/bmbf_logo_neu_skaliert_klein.svg"),
                idelogo: require("@/assets/images/elsisat/ide_logo.svg"),
                bonn: require("@/assets/images/elsisat/Bonn.svg"),
                cle: require("@/assets/images/elsisat/centerLifeEthics.svg"),
                ixd: require("@/assets/images/elsisat/ixd.svg"),
            },
            isModalVisible: false,
            modalID: 0,
        };
    },
    computed: {
        appVersion() {
            return process.env.VUE_APP_VERSION + "-" + "03eece9b, master, production, 2024-01-29 17:42";
        },
        dataVersion() {
            let dataVersion = this.$store.getters['survey/getVersion'];
            return (dataVersion != null) ? dataVersion : "unbekannt";
        }
    },
    methods: {
        createOK() {
            this.$refs.projectCreationModal.clickedOK()
        },
        importOK() {
            this.$refs.projectCreationModal.clickedOK()
        },
    }
};
</script>

<style lang="scss">
.big-logo {
    position: absolute;
    right: 64px;
    margin-top: 16px;
    width: 200px;
    z-index: 0;
}

section#navigation {
    /* navigation */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 40px;
    gap: 70px;

    left: 0;
    top: 0;
    height: 96px;

    /* Weiß */
    background: #FFFFFF;

    #main-nav {
        /* Main Nav */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0;
        gap: 60px;
        justify-content: space-between;

        width: 100%;
        height: 42px;

        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 0;

        #logo {
            /* logo */
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 12px;
            gap: 12px;

            width: 218px;
            height: 42px;

            /* Inside auto layout */
            flex: none;
            order: 0;
            flex-grow: 0;

            .icon {
                /* activity */
                width: 24px;
                height: 24px;

                svg {
                    transform: scale(1.4);

                    path {
                        stroke: $elsi-color-blue;
                        stroke-width: .08rem;
                    }
                }
            }

            .text {
                /* ELSI-Tool H&C */
                width: 258px;
                height: 34px;

                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 153.5%;
                /* identical to box height, or 34px */

                /* Blue */
                color: $elsi-color-blue;

                /* Inside auto layout */
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }

        #optional {
            /* Optional */
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0;
            gap: 24px;

            width: 522px;
            height: 38px;

            /* Inside auto layout */
            flex: none;
            order: 1;
            flex-grow: 0;

            div {
                box-sizing: border-box;

                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px;
                gap: 16px;

                height: 38px;

                color: #9CA2A6;

                /* Inside auto layout */
                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
    }
}

section#header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 40px;

    background-color: #F8F8F8;

    #hero {
        background: #FFFFFF;
        padding: 50px 90px;

        /* Card */
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        /* Inside auto layout */
        flex: auto;
        order: 0;
        align-self: stretch;

        justify-content: center;
        display: flex;
        column-gap: 1rem;

        #text {
            // 50/50 flexbox
            width: 100%;

            hgroup {
                h1 {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 72.24px;
                    line-height: 80px;
                    color: $elsi-color-schrift-normal;
                    padding-bottom: 1rem;
                }

                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24.19px;
                    line-height: 32px;
                    /* or 132% */
                    color: $elsi-color-schrift-normal;

                    padding-bottom: .8rem;
                }
            }

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                /* or 171% */

                color: $elsi-color-schrift-normal;
                padding-bottom: .8rem;
            }
        }

        #image {
            // 50/50 flexbox
            width: 100%;
            display: flex;
            align-self: center;
            justify-content: center;
        }
    }
}

.tool-text {
    height: 65px;
    padding: 10px 20px 20px 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.tool-step {
    display: flex;
    flex-direction: column;

    .tool-step-circle {
        border-radius: 50%;
        width: 38px;
        height: 38px;
        padding: 10px;
        background: $elsi-color-backdrop;
        border: none;
        text-align: center;
        font-weight: 700;
    }

}

h6 {
    padding-top: 16px;
    display: flex;
    flex-direction: row;

}

h6:before,
h6:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid $elsi-color-dunkelgrau;
    margin: auto;
}

section#tool-overview {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 32px 40px;

    .tool-column {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .tool-overview {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        background-color: white;
        padding: 40px 32px;
        justify-content: center;
        align-items: center;
        text-align: center;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
    }
}

section#cards {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 32px 40px;

    .cards {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: nowrap;
        gap: 1.5rem;
    }
}

section#faq {
    display: flex;
    flex-direction: column;
    // calc: 1rem margin from collapsible
    padding: 40px 40px calc(32px - 1rem) 40px;

    .faq {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1.5rem;
    }
}

section#ueber-uns {
    display: flex;
    flex-direction: column;
    padding: 40px 40px 32px 40px;

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 32px 40px;
        gap: 20px;

        /* Weiß */
        background: #FFFFFF;

        /* Card */
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        .logos {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: calc(40px - 1rem) 0 20px 0;
            gap: 60px;

            .logo,
            .logo img {
                height: 60px;
            }
        }
    }
}

h5 {
    font-weight: 500;
    color: $elsi-color-schrift-normal;
    padding-bottom: 5px;
}


.footer {
    padding-left: 40px;
    padding-bottom: 16px;
}

.version-info {
    color: $elsi-color-dunkelgrau;
    font-size: 10px;
}
</style>
