<template>
    <div>
        <b-card class="project-creation-card">
            <b-card-title v-if="showImport !== 'false'" style="margin-bottom: 20px">Projekt erstellen</b-card-title>
            <div class="project-creation">
                <div>
                    <label for="nameProject">Projektname</label> <b-badge style="color: red">*erforderlich</b-badge>
                    <input v-model="projectName" type="text" class="form-control test" id="nameProject"
                        placeholder="z.B. ACRONYM" required>
                    <label for="wantedResult">Angestrebtes Ergebnis</label>
                    <input v-model="wantedResult" type="text" class="form-control test" id="wantedResult"
                        placeholder="z.B. App">
                    <label for="field">Technologiefeld</label>
                    <input v-model="field" type="text" class="form-control test" id="field" placeholder="z.B. Robotik">
                    <label for="targetGroup">Zielgruppe</label>
                    <input v-model="targetGroup" type="text" class="form-control test" id="targetGroup"
                        placeholder="z.B. chronisch erkrankte Menschen">
                </div>
                <div>
                    <label for="projectDescription">Beschreibung</label>
                    <textarea v-model="projectDescription" type="text" class="form-control test" id="projectDescription"
                        placeholder="z.B. Forschungsprojekt, um zukünftig ..."
                        style="height: 240px; justify-content: start;"></textarea>
                    <p style="font-size: 12px">Hinweis: Die Daten dienen nur zur Wiedererkennung Ihres Projektes und werden
                        nicht ausgelesen. Datenschutz ist uns wichtig, daher werden alle Daten wie bspw. Projektname etc.
                        nur auf dem
                        benutzten Gerät gespeichert. Wenn Sie in Ihrem Browser 'Cookies und andere Websitedaten' löschen,
                        verschwinden die Eingaben.</p>
                </div>
            </div>
        </b-card>
        <b-card class="project-creation-card" v-if="showImport !== 'false'" style="border: none">
            <b-card-title style="margin-bottom: 20px">Projekt importieren</b-card-title>
            <ImportJson ref="importJSON"></ImportJson>
        </b-card>
    </div>
</template>

<script>
import Button from "@/components/Button.vue";
import ImportJson from "@/components/ImportJSON.vue";

export default {
    name: "ProjectCreationModal",
    props: {
        showImport: String
    },
    components: {
        Button,
        ImportJson
    },
    computed: {
        projectName: {
            //GETTER
            get() {
                return this.$store.getters['survey/getProjectMeta'].nameProject
            },
            //SETTER
            set(newValue) {
                this.nameProject = newValue
            }
        },
        wantedResult: {
            //GETTER
            get() {
                return this.$store.getters['survey/getProjectMeta'].wantedResult
            },
            //SETTER
            set(newValue) {
                this.wantedResultVar = newValue
            }
        },
        field: {
            //GETTER
            get() {
                return this.$store.getters['survey/getProjectMeta'].field
            },
            //SETTER
            set(newValue) {
                this.fieldVar = newValue
            }
        },
        targetGroup: {
            //GETTER
            get() {
                return this.$store.getters['survey/getProjectMeta'].targetGroup
            },
            //SETTER
            set(newValue) {
                this.targetGroupVar = newValue
            }
        },
        projectDescription: {
            //GETTER
            get() {
                return this.$store.getters['survey/getProjectMeta'].projectDescription
            },
            //SETTER
            set(newValue) {
                this.projectDescriptionVar = newValue
            }
        },
    },
    mounted() {
        this.updateInputValues()
    },
    updated() {
        this.updateInputValues()
    },
    methods: {
        updateInputValues() {
            this.nameApplicant = this.$store.getters['survey/getProjectMeta'].nameApplicant
            this.nameProject = this.$store.getters['survey/getProjectMeta'].nameProject
            this.wantedResultVar = this.$store.getters['survey/getProjectMeta'].wantedResult
            this.fieldVar = this.$store.getters['survey/getProjectMeta'].field
            this.targetGroupVar = this.$store.getters['survey/getProjectMeta'].targetGroup
            this.projectDescriptionVar = this.$store.getters['survey/getProjectMeta'].projectDescription
        },
        clickedOK() {
            if (this.nameProject !== '') {
                this.showAlert = false
                let payload = { nameApplicant: this.nameApplicant, nameProject: this.nameProject, wantedResult: this.wantedResultVar, field: this.fieldVar, targetGroup: this.targetGroupVar, projectDescription: this.projectDescriptionVar };
                this.$store.dispatch('survey/setProjectMeta', payload)
                if (this.$route.name.toLowerCase() !== 'uebersicht')
                    this.$router.push('/uebersicht')
            }
            else {
                this.$vs.notify({
                    time: 6000,
                    title: 'Projekttitel fehlt!',
                    text: 'Bitte geben Sie einen Projektnamen ein.',
                    color: 'danger'
                })
            }


        },
    },
    data() {
        return {
            nameApplicant: '',
            nameProject: '',
            wantedResultVar: '',
            fieldVar: '',
            targetGroupVar: '',
            projectDescriptionVar: '',
            showAlert: false,
        }
    }
}
</script>

<style lang="scss" scoped>
.test {
    background-color: #F8F9FB;
    border: none;
    margin-top: 16px;
    resize: none;
    width: 100%;
    display: inline;
    margin-bottom: 16px;
}

.project-creation-card {
    border: none;
    padding: 20px;
    height: fit-content;
}

.project-creation {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1.5rem;
    justify-items: start;
    margin: 0;
    padding: 0;

}


#options-panel {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 40px;

    .empfohlen {
        border-top: 8px solid #4BB3D4;

        &::before {
            content: 'empfohlen';
            color: $elsi-color-lightblue;
            position: absolute;
            top: -2px;
            margin-right: -240px;
            font-weight: 600;
            font-size: 12px;
            line-height: 140%;
        }
    }

    .option {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px 32px;
        gap: 12px;

        width: 310px;
        height: 601px;

        background: #FFFFFF;

        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
        border-radius: 6px;

        .inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 32px;
            justify-content: space-between;
            flex: 1;
            flex-wrap: wrap;

            padding: 0;
            width: 310px;

            .text {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                gap: 16px;

                width: 231px;

                h2 {
                    width: 230px;
                    height: 26px;

                    font-weight: 500;
                    font-size: 18.48px;
                    line-height: 140%;

                    color: #121212;
                }

                ul {
                    width: 230px;

                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;

                    color: #485159;

                    list-style: disc;
                    border-bottom: 2px solid #E5ECF2;

                    li {
                        margin-left: 1.5rem;
                        margin-bottom: 1rem;
                    }
                }

                .info {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;

                    color: $elsi-color-schrift-graublau;
                }
            }
        }
    }
}</style>
