<template>
  <div v-if="!showImport">
  <div id="options-panel">

    <div class="option empfohlen">
      <div class="inner">
        <img class="img-fluid" src="~@/assets/images/elements/HM-2.png" alt="">
        <div class="text">
          <h2>Projekt erstellen</h2>
          <ul>
            <li>Das Tool selbst ausprobieren</li>
            <li>Eigenes Projekt unter einem Namen speichern</li>
            <li>Im Team oder alleine bearbeiten</li>
          </ul>
          <p class="info">
            Mit dieser Option können Sie Ihr eigenes Forschungsprojekt unter die Lupe nehmen.
          </p>
        </div>
        <Button v-b-modal.modal-multi-3 label="Projekt erstellen" :chevron="true"/>
      </div>
    </div>
    <div class="option">
      <div class="inner">
        <img class="img-fluid" src="~@/assets/images/elements/HM-3.png" alt="">
        <div class="text">
          <h2>Projekt importieren</h2>
          <ul>
            <li>Tool wurde bereits ausgefüllt</li>
            <li>JSON Datei für Import vorhanden</li>
          </ul>
          <p class="info">
            Diese Option ist nur dann sinnvoll, wenn Sie bereits ein Projekt im Tool bearbeitet haben, das Sie
            fortsetzen möchten.
          </p>
        </div>
        <Button v-b-modal.modal-multi-4 label="Projekt importieren" :chevron="true" btnStyle="btn-secondary"/>
      </div>
    </div>
  </div>
  </div>
  <div v-else-if="showImport">
        <ImportJSON></ImportJSON>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import ImportJSON from "@/components/ImportJSON.vue"

export default {
  name: "HomepageModal",
  data() {
      return {
        showImport: false,
      }
    },
  components: {
    Button,
    ImportJSON
  },
  methods: {
    clickedImport() {
      this.showImport = !this.showImport
    }
  }
}
  
</script>

<style lang="scss" scoped>
#options-panel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 40px;
  justify-content: center;

  .empfohlen {
    border-top: 8px solid #4BB3D4;

    &::before {
      content: 'empfohlen';
      color: $elsi-color-lightblue;
      position: absolute;
      top: -2px;
      margin-right: -240px;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
    }
  }

  .option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 40px 32px;
    gap: 12px;

    width: 310px;
    height: 601px;

    background: #FFFFFF;

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      justify-content: space-between;
      flex: 1;
      flex-wrap: wrap;

      padding: 0;
      width: 310px;

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        gap: 16px;

        width: 231px;

        h2 {
          width: 230px;
          height: 26px;

          font-weight: 500;
          font-size: 18.48px;
          line-height: 140%;

          color: #121212;
        }

        ul {
          width: 230px;

          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: #485159;

          list-style: disc;
          border-bottom: 2px solid #E5ECF2;

          li {
            margin-left: 1.5rem;
            margin-bottom: 1rem;
          }
        }

        .info {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;

          color: $elsi-color-schrift-graublau;
        }
      }
    }
  }
}
</style>
